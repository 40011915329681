import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {postApi} from "../../../Api/Api";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await postApi(formData, "admin/login");

    if (response.token) {
      Cookies.set("authToken", response.token, { expires: 1.5 / 24 });
      toast.success("Login successful!");
      navigate("/admin");
    } else {
      toast.error(response.message);
    }
  };

  return (
    <div className="w-[100%] h-screen flex justify-center items-center">
      <div className="w-[30%] h-auto shadow-md rounded-lg bg-[#f7f7fa]">
        <h2 className="ml-[42%] pt-4 text-3xl font-bold">Admin Login</h2>
        <form className="w-[80%] mx-auto" onSubmit={handleSubmit}>
          <div className="px-5 py-5 flex flex-col gap-8">
            <div className="flex flex-col">
              <label htmlFor="name">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your Email"
                required
                className="py-3 px-4 mt-1 text-base outline-none rounded-xl"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="name">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter your Password"
                required
                className="py-3 px-4 mt-1 text-base outline-none rounded-xl"
              />
            </div>
          </div>
          <div className="flex flex-col px-5 py-5 items-center">
            <button className="w-full h-10 bg-[#0000FF] py-1 rounded-lg text-xl text-white mb-3">
              Login
            </button>
            <p className="">
              Don't have account ?{" "}
              <NavLink to={"/admin-register"} className="text-[#6495ED]">
                Sign Up
              </NavLink>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
