import React, { useEffect, useState } from "react";
import { SiChatbot } from "react-icons/si";
import Chat from "./components/chat";
import Cookies from "js-cookie";

const Home = () => {
  const [showChat, setShowChat] = useState(false);

  const handleChatClick = () => {
    setShowChat(true);
  };

  useEffect(()=>{
    setTimeout(() => {
      setShowChat(true)
    }, 1500);
  })

  useEffect(() => {
    Cookies.remove("is_email_verified");
    Cookies.remove("token");
    localStorage.removeItem("bot_messages")
    localStorage.removeItem("filter")
  }, []);

  const handleCloseChat = () => {
    Cookies.remove("is_email_verified");
    Cookies.remove("token");
    localStorage.removeItem("connectedToProfessional");
    localStorage.removeItem("operatorMessage");
    localStorage.removeItem("bot_messages")
    localStorage.removeItem("filter")
    setShowChat(false);
  };
  return (
    <div className="bg-[#61121f]">
      {!showChat && (
        <div className="w-[5%] fixed bottom-10 right-10 ">
          <div
            className="w-16 h-16 border-[1px] bg-[#00FFFF] cursor-pointer border-[#33ECFF] rounded-full flex justify-center items-center text-4xl text-[#0000FF]"
            onClick={handleChatClick}
          >
            <SiChatbot />
          </div>
        </div>
      )}
      {showChat && <Chat handleCloseChat={handleCloseChat} />}
    </div>
  );
};

export default Home;
