const { postApi } = require("../Api/Api");

const get_payment_details = async (token) => {
  try {
    if (token) {
      const formData = { token: token };
      const paymentDetails = await postApi(formData, "payment/get-payment");
      if (paymentDetails) {
        const subscription = paymentDetails?.data?.isSubscribed;
        return subscription;
      }
      return false;
    } else {
      return false;
    }
  } catch {
    console.log("Error at get payment details..");
    return null;
  }
};

module.exports = {
  get_payment_details,
};
