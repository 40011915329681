const postApi = async (payload, endpoint, token = null) => {
  try {
    const URL = `${process.env.REACT_APP_API_URL}/${endpoint}`;

    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await fetch(URL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    const res = await response.json();

    return res;
  } catch (e) {
    console.log(e);
  }
};

const getApi = async (endpoint, token = null) => {
  try {
    const URL = `${process.env.REACT_APP_API_URL}/${endpoint}`;

    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await fetch(URL, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
  }
};

const uploadFile = async (payload, endpoint) => {
  try {
    const URL = `${process.env.REACT_APP_API_URL}/${endpoint}`;
    const response = await fetch(URL, {
      method: "POST",
      body: payload,
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`File upload failed: ${errorMessage}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("File upload error:", error);
    throw error;
  }
};

export { postApi, uploadFile, getApi };
