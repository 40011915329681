import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/404";
import Login from "./pages/Admin/Login";
import Register from "./pages/Admin/Register";
import Home from "./pages/Home";
import ProtectedRoute from "./components/protectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Admin/Dashboard";
import EmailVerified from "./pages/User/EmailVerify";
import DynamicForm from "./pages/Home/components/filterForm";
import UserDynamicForm from "./pages/Home/components/userFilterForm";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/admin-login" element={<Login />} />
        <Route path="/admin-register" element={<Register />} />
        <Route path="/admin" element={<ProtectedRoute element={Dashboard} />} />
        <Route path="/" element={<Home />} />
        <Route path="operator-filter/:id" element={<DynamicForm />} />
        <Route path="user-filter/:id" element={<UserDynamicForm />} />
        <Route path="/verifyEmail" element={<EmailVerified />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </div>
  );
};

export default App;
